interface NetworkShortName {
  shortName: string
  chainId: bigint
}

const networks: NetworkShortName[] = [
  { chainId: 1n, shortName: 'eth' },
  { chainId: 810n, shortName: 'h1' },
  { chainId: 11155111n, shortName: 'sep' },
]

/**
 * A static shortName<->chainId dictionary
 * E.g.:
 *
 * {
 *   eth: '1',
 *   gor: '5',
 *   ...
 * }
 */
type Chains = Record<string, string>

const chains = networks.reduce<Chains>((result, { shortName, chainId }) => {
  result[shortName] = chainId.toString()
  return result
}, {})

export default chains
